export default function NuestroEquipo() {
  return (
    <>
      <div className="container marketing">
        <div className="row mb-5">
          <div className="col-md-12">
            <h3 className="featurette-heading-title fw-normal lh-1 text-center">
              Directorio
            </h3>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/directorio1.webp"
              />
              <p>
                <strong>TOMÁS ABURTO YAÑEZ</strong>
                <br />
                PRESIDENTE
                <br />
                Constructor Civil
                <br />
                Académico Educ. Superior
                <br />
                Mg. Dirección y Gestión en Educación
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/directorio2.webp"
              />
              <p>
                <strong>ROSANA TAPIA GARRIDO</strong>
                <br />
                DIRECTORA GENERAL
                <br />
                Téc. Contador General
                <br />
                Intérprete LSCh.
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/directorio3.webp"
              />
              <p>
                <strong>VALENTINA FLORES G.</strong>
                <br />
                SECRETARIA <br />
                Estudiante Profesora
                <br />
                Educ. Especial
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/directorio4.webp"
              />
              <p>
                <strong>NAZARET CAMPOS</strong>
                <br />
                TESORERA
                <br />
                Profesora Educ.
                <br />
                General Básica
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/directorio5.webp"
              />
              <p>
                <strong>EVELYN BETANZO F.</strong>
                <br />
                DIRECTORA
                <br />
                Fonoaudióloga
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/directorio6.webp"
              />
              <p>
                <strong>RODRIGO BETANZO F.</strong>
                <br />
                DIRECTOR
                <br />
                Fonoaudiólogo
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/directorio7.webp"
              />
              <p>
                <strong>ELIZABETH ABURTO T.</strong>
                <br />
                DIRECTORA
                <br />
                Estudiante Profesora
                <br />
                Educ. Especial
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/directorio8.webp"
              />
              <p>
                <strong>ALEXIS DACHELET J.</strong>
                <br />
                DIRECTOR
                <br />
                Contador General
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="row mb-5">
          <div className="col-md-12">
            <h3 className="featurette-heading-title fw-normal lh-1 text-center">
              COORDINADORAS DE ZONA
            </h3>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/coordinadora1.webp"
              />
              <p>
                <strong>KATHERINE GÓMEZ G.</strong>
                <br />
                COORDINADORA
                <br />
                ZONA NORTE
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/coordinadora2.webp"
              />
              <p>
                <strong>CLAUDIA MARTÍNEZ P.</strong>
                <br />
                COORDINADORA
                <br />
                ZONA CENTRO
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/coordinadora3.webp"
              />
              <p>
                <strong>ALEJANDRA FERNANDOY.</strong>
                <br />
                COORDINADORA
                <br />
                ZONA CENTRO-SUR
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/coordinadora4.webp"
              />
              <p>
                <strong>MAURA MELLADO.</strong>
                <br />
                COORDINADORA
                <br />
                ZONA SUR
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="row mb-5">
          <div className="col-md-12">
            <h3 className="featurette-heading-title fw-normal lh-1 text-center">
              PROFESORES SORDOS NIVELES ADULTOS
            </h3>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/sordo1.webp" />
              <p>
                <strong>M. JOSÉ ZAMUDIO</strong>
                <br />
                SORDA
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/sordo2.webp" />
              <p>
                <strong>ALEXIS DACHELET</strong>
                <br />
                SORDO
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/sordo3.webp" />
              <p>
                <strong>MAURICIO MARTÍNEZ</strong>
                <br />
                SORDO
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/sordo4.webp" />
              <p>
                <strong>ALFONSO MEZA</strong>
                <br />
                SORDO
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/sordo5.webp" />
              <p>
                <strong>WILCARIS HERNÁNDEZ</strong>
                <br />
                SORDA
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="row mb-5">
          <div className="col-md-12">
            <h3 className="featurette-heading-title fw-normal lh-1 text-center">
              PROFESORES NIVELES ADULTOS
            </h3>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/padulto1.webp"
              />
              <p>
                <strong>EVELYN BETANZO F.</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/padulto2.webp"
              />
              <p>
                <strong>ELIZABETH ABURTO T.</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/padulto3.webp"
              />
              <p>
                <strong>CLAUDIA MARTÍNEZ P.</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/padulto4.webp"
              />
              <p>
                <strong>KATHERINE GÓMEZ G.</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/padulto5.webp"
              />
              <p>
                <strong>ROSANA TAPIA G.</strong>
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="row mb-5">
          <div className="col-md-12">
            <h3 className="featurette-heading-title fw-normal lh-1 text-center">
              PROFESORES NIVELES KIDS
            </h3>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/pkids1.webp" />
              <p>
                <strong>FEBE TORRES H.</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/pkids2.webp" />
              <p>
                <strong>ALEJANDRA FERNANDOY</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/pkids3.webp" />
              <p>
                <strong>KATHERINE HUENCHO</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/pkids4.webp" />
              <p>
                <strong>JACQUELINE MUÑOZ</strong>
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="row mb-5">
          <div className="col-md-12">
            <h3 className="featurette-heading-title fw-normal lh-1 text-center">
              ADMINISTRADORES NIVELES ADULTOS
            </h3>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/aadulto1.webp"
              />
              <p>
                <strong>JONERCY GONZÁLEZ</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/aadulto2.webp"
              />
              <p>
                <strong>PATRICIA SANTANDER</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/aadulto3.webp"
              />
              <p>
                <strong>PAULINA PAILLAQUEO</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/aadulto4.webp"
              />
              <p>
                <strong>DANITZA ESPINOZA</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/aadulto5.webp"
              />
              <p>
                <strong>SARAI CÉSPEDES</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/aadulto6.webp"
              />
              <p>
                <strong>MIRTA GALLEGUILLOS</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/aadulto7.webp"
              />
              <p>
                <strong>SILVANA BARO</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/aadulto8.webp"
              />
              <p>
                <strong>MARGARITA TOLEDO</strong>
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="row mb-5">
          <div className="col-md-12">
            <h3 className="featurette-heading-title fw-normal lh-1 text-center">
              ADMINISTRADORES NIVELES KIDS
            </h3>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/akids1.webp" />
              <p>
                <strong>BELEN VEGA </strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/akids2.webp" />
              <p>
                <strong>SAHIEN FREDES</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/akids3.webp" />
              <p>
                <strong>ELIAN MEDINA</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/akids4.webp" />
              <p>
                <strong>ALISSON VERGARA</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/akids5.webp" />
              <p>
                <strong>ISIDORA DACHELET</strong>
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="row mb-5">
          <div className="col-md-12">
            <h3 className="featurette-heading-title fw-normal lh-1 text-center">
              COORDINADORAS RELACIONES PÚBLICAS
            </h3>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/publica1.webp"
              />
              <p>
                <strong>MARISOL FAUNDEZ</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img
                className="img-personal"
                src="../img/personal/publica2.webp"
              />
              <p>
                <strong>SILVANA BARO</strong>
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="row mb-5">
          <div className="col-md-12">
            <h3 className="featurette-heading-title fw-normal lh-1 text-center">
              FACILITADORES LSCh.
            </h3>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/lsch1.webp" />
              <p>
                <strong>ELIZABETH ABURTO</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/lsch2.webp" />
              <p>
                <strong>BENJAMÍN MARZÁN</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/lsch3.webp" />
              <p>
                <strong>EVELYN BETANZO F.</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/lsch4.webp" />
              <p>
                <strong>KATHERINE GÓMEZ G.</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/lsch5.webp" />
              <p>
                <strong>MAURA MELLADO</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/lsch6.webp" />
              <p>
                <strong>ROSANA TAPIA G.</strong>
              </p>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="row mb-5">
          <div className="col-md-12">
            <h3 className="featurette-heading-title fw-normal lh-1 text-center">
              COORDINADORAS REDES SOCIALES
            </h3>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/personal/social1.webp" />
              <p>
                <strong>AHIRAM HERNÁNDEZ</strong>
              </p>
            </div>
            <div
              className="form-group col-md-3"
              style={{ textAlign: "center" }}
            >
              <img className="img-personal" src="../img/logo.webp" />
              <p>
                <strong>DANIELA HULIPAN</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
