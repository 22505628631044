export default function Historia() {
  return (
    <div className="container marketing">
      <div className="row mb-5">
        <div className="col-md-12">
          <h3 className="featurette-heading-title fw-normal lh-1 text-center">
            NUESTRA HISTORIA DE SERVICIO Y COMPROMISO CRISTIANO
          </h3>
          <p className="lead text-center">
            La Fundación Vence las Barreras nació en el año 2013, impulsada por
            la inquietud y la visión de los pastores Tomás Aburto Yañez y Rosana
            Tapia Garrido, quienes, guiados por su fe cristiana, reconocieron la
            necesidad y falta de oportunidades que enfrentan las personas sordas
            en el acceso a información esencial en todas las áreas de la vida
            cotidiana. <br /> Fue entonces cuando decidieron dar un paso
            valiente y crear oportunidades en una misión de servicio a los
            demás, guiados por el amor y la compasión de Cristo.
          </p>
        </div>
      </div>
      <br />
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">
            NUESTROS INICIOS
          </h2>
          <p className="lead">
            En esa búsqueda por hacer una diferencia significativa, nació la
            Fundación Vence las Barreras, con un propósito claro y definido:
            capacitar a los oyentes en la Lengua de Señas Chilena, con el
            objetivo de atender las necesidades que enfrentan las personas
            sordas en una sociedad que, a veces, parece no entender sus retos y
            desafíos. <br />
            Posteriormente, se organizó y desarrolló una red de voluntarios,
            compuesta por hombres y mujeres, sordos y oyentes, todos movidos por
            el amor cristiano y dispuestos a prestar sus servicios de manera
            gratuita para apoyar y cumplir los objetivos de la Fundación. Como
            siervos de Dios, unieron sus manos y corazones en un solo propósito:
            ser instrumentos de amor y esperanza para las personas sordas y sus
            familias.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/logo.png"
            width={"350"}
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">
            NUESTROS PRIMEROS PASOS
          </h2>
          <p className="lead">
            En sus primeros cinco años, la Fundación se enfocó en la realización
            de capacitaciones de manera presencial en la ciudad de Talca. <br />{" "}
            Con la ayuda de seis valiosos voluntarios, formaron el directorio de
            la Fundación y sembraron las bases de un futuro lleno de esperanza y
            crecimiento.
          </p>
        </div>
        <div className="col-md-5">
          <img
            src="../img/page/Imagen26.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">NUESTROS LOGROS</h2>
          <p className="lead">
            Hoy en día, la Fundación Vence las Barreras ha expandido su alcance
            a lo largo de todo nuestro país. Contamos con equipos de trabajo en
            distintas zonas, desde el norte hasta el sur, y un equipo de
            educación conformado por docentes tanto sordos como oyentes,
            administradores y colaboradores, todos comprometidos con nuestra
            misión cristiana de inclusión y servicio. <br />
            A través de nuestra labor, dictamos cursos en diferentes niveles,
            tanto para adultos como para niños, de forma online, permitiéndonos
            llegar a cada rincón de nuestra nación y llevar la esperanza y el
            conocimiento a aquellos que anhelan crecer y desarrollarse en su
            vida personal y espiritual. <br />
            En Fundación Vence las Barreras, nuestra historia es testimonio del
            poder del amor y el servicio cristiano. Guiados por la fe y el amor
            de Cristo, seguiremos adelante con la convicción de vencer barreras
            y ser un faro de luz y esperanza para las personas sordas y sus
            familias.
          </p>
          <p className="img-title">
            ¡Únete a nosotros en esta apasionante travesía de fe y amor
            cristiano!
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen27.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
    </div>
  );
}
