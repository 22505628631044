import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import logo from "../logo.webp";

export default function Carrusel() {
  const elementos = [
    {
      title: "¡Bienvenidos A Fundación Vence Las Barreras!",
      subtitle: "",
      image: "/img/imagen3.jpg",
    },
    {
      title: "Con Nuestras Manos, Vencemos Barreras Y Entregamos Esperanza",
      subtitle: "",
      image: "/img/imagen2.jpg",
    },
  ];
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        controls={true}
        indicators={true}
        fade
        interval={5000}
      >
        {elementos.map((data) => (
          <Carousel.Item>
            <img className="carousel-img" src={data.image} alt="" />
            <div className="carousel-img-opacity"></div>
            <Carousel.Caption className="text-center">
              <img className="carousel-logo" src={logo} alt="" />
              <h2 className="img-title">
                <span>{data.title}</span>
              </h2>
              <p>{data.subtitle}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="container marketing oracion-opacity">
        <div className="row featurette">
          <div className="col-md-12">
            <p className="oracion text-start">
              ¿Cómo, pues, invocarán a aquel en el cual no han creído? <br />
              ¿Y cómo creerán en aquel de quien no han oído? <br />
              ¿Y cómo oirán sin haber quien les predique? <br />
              Romanos 10:14
            </p>
          </div>
        </div>
      </div>
      <hr className="divider" />
    </>
  );
}
