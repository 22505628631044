let images = []

for (let i = 0; i<20; i++) {
    const random1 = Math.floor(Math.random() * 500) + 300;
    const random2 = Math.floor(Math.random() * 500) + 300;
    images.push(`https://picsum.photos/${random1}/${random2}`)
}

export default function Agenda() {
    return (
        <div className="container marketing">
            <div className="row mb-5">
                <div className="col-md-12">
                    <h3 className="featurette-heading-title fw-normal lh-1 text-center">
                        AGENDA DE IMÁGENES
                    </h3>
                </div>
            </div>
            <div className="container mt-5">
                <div className='gallery'>
                    {images.map((img, index) => (
                         <a href={`${img}`} data-caption="Image caption">
                            <div className="gallery-item">
                                <img src={`${img}`} alt={`${img}`} />
                                <div className="overlay">
                                    <div className="overlay-content">
                                        <h3 className="item-title">Imagen 1</h3>
                                        <p className="item-description">Descripción de la imagen 1</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}