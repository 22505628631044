import { Link, useLocation } from "react-router-dom";
import logo from "../logo.webp";
import React, { useState } from "react";
import Heart from "./assets/heart.svg"
import Computer from "./assets/computer.svg"

export default function Header() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const navbarToggler = document.querySelector(".navbar-toggler");
    const navbarCollapse = document.querySelector(".navbar-collapse");
    navbarToggler.classList.add("collapsed");
    navbarToggler.setAttribute("aria-expanded", false);
    navbarCollapse.classList.remove("show");
  };

  const location = useLocation().pathname;

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light">
          <div className="container-fluid">
            <Link to={"/"} onClick={scrollToTop} className="navbar-brand">
              <img className="navbar-img" src={logo} alt="" />
              <label className="navbar-title">
                Fundación Vence Las Barreras
              </label>
            </Link>
            <button
              className="navbar-toggler float-end"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* <div className="navbar-buttons float-end">
                        <a className="btn btn-primary me-1" href="/">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M14 4L17.5 4C20.5577 4 20.5 8 20.5 12C20.5 16 20.5577 20 17.5 20H14M15 12L3 12M15 12L11 16M15 12L11 8" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Ir a Intranet
                        </a>
                    </div> */}
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                <li className="nav-item">
                  <Link
                    to="/"
                    onClick={scrollToTop}
                    className={`nav-link ${location === "/" ? "active" : ""}`}
                    aria-current="page"
                  >
                    Inicio
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="sobre-nosotros-dropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Sobre Nosotros
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="sobre-nosotros-dropdown"
                  >
                    <li className="nav-item">
                      <Link
                        to="/somos"
                        onClick={scrollToTop}
                        className={`nav-link ${
                          location === "/somos" ? "active" : ""
                        }`}
                      >
                        Somos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/historia"
                        onClick={scrollToTop}
                        className={`nav-link ${
                          location === "/historia" ? "active" : ""
                        }`}
                      >
                        Historia
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/principios"
                        onClick={scrollToTop}
                        className={`nav-link ${
                          location === "/principios" ? "active" : ""
                        }`}
                      >
                        Principios
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/que-hacemos"
                        onClick={scrollToTop}
                        className={`nav-link ${
                          location === "/que-hacemos" ? "active" : ""
                        }`}
                      >
                        Qué Hacemos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/nuestro-equipo"
                        onClick={scrollToTop}
                        className={`nav-link ${
                          location === "/nuestro-equipo" ? "active" : ""
                        }`}
                      >
                        Nuestro Equipo
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="novedades-dropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Contenido
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="novedades-dropdown"
                  >
                    <li className="nav-item">
                      <Link
                        to="/noticias"
                        onClick={scrollToTop}
                        className={`nav-link ${
                          location === "/noticias" ? "active" : ""
                        }`}
                      >
                        Noticias
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/agenda"
                        onClick={scrollToTop}
                        className={`nav-link ${
                          location === "/agenda" ? "active" : ""
                        }`}
                      >
                        Agenda
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contacto"
                    onClick={scrollToTop}
                    className={`nav-link ${
                      location === "/contacto" ? "active" : ""
                    }`}
                  >
                    Contacto
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/inscripcion"}
                    onClick={scrollToTop}
                    className={`nav-link ${
                      location === "/inscripcion" ? "active" : ""
                    }`}
                  >
                    Inscripción
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="btn btn-primary btn-navbar me-1" href="/aulavirtual" target="_blank">
                   <img src={Computer} alt="Border" width={16} height={16} />
                    Aula Virtual
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/quiero-donar"}
                    onClick={scrollToTop}
                    className="btn btn-danger btn-navbar me-1"
                  >
                    <img src={Heart} alt="Border" width={16} height={16} />
                    Quiero Donar
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
