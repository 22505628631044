export default function Principios() {
  return (
    <div className="container marketing">
      <div className="row mb-5">
        <div className="col-md-12">
          <h3 className="featurette-heading-title fw-normal lh-1 text-center">
            PRINCIPIOS
          </h3>
          <p className="lead text-center">
            TRANSFORMANDO VIDAS A TRAVÉS DE LA INCLUSIÓN Y SOLIDARIDAD
          </p>
        </div>
      </div>
      <br />
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">
            NUESTRO PROPÓSITO
          </h2>
          <p className="lead">
            En Fundación Vence las Barreras, nuestro propósito es guiado por
            principios cristianos que nos inspiran a contribuir a una sociedad
            más inclusiva, solidaria y participativa. Creemos que cada
            individuo, sin importar su condición, es amado y valorado por Dios,
            y nuestra misión es ser instrumentos para disminuir las brechas de
            desigualdad que enfrentan las personas sordas y sus familias.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen28.webp"
            width={"350"}
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">
            VALOR DE LAS PERSONAS
          </h2>
          <p className="lead">
            Reconocemos que la discapacidad auditiva no define ni limita el
            valor y propósito de una persona. Entendemos la importancia de
            brindar accesibilidad a la información de manera global, para que
            aquellos que padecen pérdida auditiva puedan superar carencias en
            educación, salud, trabajo, seguridad social y atención espiritual.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen29.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">
            NUESTRA MOTIVACIÓN
          </h2>
          <p className="lead">
            A través del amor de Cristo, trabajamos incansablemente para vencer
            las barreras que impiden a las personas sordas alcanzar su máximo
            potencial. Creemos que cada individuo merece ser tratado con
            dignidad y respeto, y es nuestro llamado cristiano asegurarnos de
            que encuentren el apoyo y la comprensión que necesitan.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen30.webp"
            width={"350"}
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">NUESTRA ACTITUD</h2>
          <p className="lead">
            En la Fundación, nos involucramos activamente con un corazón
            generoso y compasivo, buscando extender una mano amiga a aquellos
            que enfrentan desafíos debido a la discapacidad auditiva. Nuestra
            labor va más allá de brindar capacitación en Lengua de Señas
            Chilena; también nos preocupamos por atender las necesidades
            espirituales de las personas sordas, guiándolas hacia la Palabra de
            Dios como fuente de fe y esperanza.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen31.webp"
            width={"350"}
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">
            TRANSFORMANDO VIDAS
          </h2>
          <p className="lead">
            En Fundación Vence las Barreras, vemos la necesidad de transformar
            vidas, ya que cada pequeño acto de amor y servicio tiene un impacto
            eterno. Invitamos a todos los cristianos a unirse a nuestra causa,
            para que juntos seamos instrumentos del amor de Dios y marcar una
            diferencia significativa en la vida de las personas sordas y sus
            familias.
          </p>
          <p className="img-title">
            ¡Unámonos en esta misión, donde la fe y la inclusión se entrelazan
            para glorificar al Señor y cambiar vidas!
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen32.webp"
            width={"350"}
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
    </div>
  );
}
