export default function Somos() {
  return (
    <div className="container marketing">
      <div className="row mb-5">
        <div className="col-md-12">
          <h3 className="featurette-heading-title fw-normal lh-1 text-center">
            FUNDACIÓN VENCE LAS BARRERAS, CREANDO CONEXIONES
          </h3>
          <p className="lead text-center">
            Somos una organización cristiana comprometida con la misión de
            vencer las barreras de la comunicación para las personas con
            discapacidad auditiva, reduciendo la brecha entre oyentes y sordos.
          </p>
        </div>
      </div>
      <br />
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">
            NUESTROS INICIOS
          </h2>
          <p className="lead">
            Desde nuestro inicio en el año 2013, en Fundación Vence las Barreras
            hemos capacitado a niños, jóvenes y adultos en el conocimiento de la
            Lengua de Señas Chilena, permitiéndoles comunicarse de manera
            efectiva con personas sordas y fomentando su inserción social,
            educacional y laboral. <br />
            Este valioso trabajo no sería posible sin el esfuerzo y dedicación
            de nuestros voluntarios sordos y oyentes, quienes generosamente
            ofrecen su tiempo para alcanzar nuestros nobles objetivos.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen14.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">
            ACCESIBILIDAD E INCLUSIÓN
          </h2>
          <p className="lead">
            En nuestra Fundación, la accesibilidad y la inclusión son pilares
            fundamentales. <br /> Por ello, ofrecemos capacitación gratuita en
            Lengua de Señas Chilena a los beneficiarios oyentes, lo que amplía
            el alcance de conocimiento en esta valiosa herramienta de
            comunicación. <br />
            Asimismo, brindamos apoyo y acompañamiento a los beneficiarios
            sordos en diversas instancias, asegurando que accedan a los
            servicios y oportunidades que ofrece nuestra sociedad.
          </p>
        </div>
        <div className="col-md-5">
          <img
            src="../img/page/Imagen20.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">NUESTRO ALCANCE</h2>
          <p className="lead">
            Nuestra presencia se extiende a distintas regiones de Chile,
            contando con coordinadores en Antofagasta, Metropolitana, Maule y
            Los Lagos. Además, nuestro modelo de voluntariado online nos permite
            llegar a cada rincón del territorio nacional, garantizando que nadie
            quede excluido. <br />
            En estos 10 años de compromiso y servicio, hemos logrado vincular a
            más de 70 colaboradores voluntarios cristianos, quienes comparten
            nuestra pasión y propósito, capacitando a más de 500 personas a lo
            largo del país.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen21.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">NUESTRO DESAFIO</h2>
          <p className="lead">
            Fundación Vence las Barreras es sinónimo de cambio, superación y
            crecimiento. Estamos decididos a seguir venciendo barreras y creando
            conexiones significativas en cada vida que tocamos.
          </p>
          <p className="img-title">
            <strong>
              ¡Únete a nosotros en esta inspiradora travesía de inclusión y amor
              cristiano!
            </strong>
          </p>
        </div>
        <div className="col-md-5">
          <img
            src="../img/page/Imagen22.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
    </div>
  );
}
