import "./App.scss";
import Header from "./components/Header";
import Carrusel from "./components/Carrusel";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Inicio from "./components/pages/Inicio";
import Somos from "./components/pages/Somos";
import Historia from "./components/pages/Historia";
import Principios from "./components/pages/Principios";
import QueHacemos from "./components/pages/QueHacemos";
import Agenda from "./components/pages/Agenda";
import Noticias from "./components/pages/Noticias";
import NuestroEquipo from "./components/pages/NuestroEquipo";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <main>
          <Carrusel />
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/somos" element={<Somos />} />
            <Route path="/historia" element={<Historia />} />
            <Route path="/principios" element={<Principios />} />
            <Route path="/que-hacemos" element={<QueHacemos />} />
            <Route path="/nuestro-equipo" element={<NuestroEquipo />} />
            <Route path="/noticias" element={<Noticias />} />
            <Route path="/agenda" element={<Agenda />} />
          </Routes>
          <Footer />
        </main>
      </Router>
    </div>
  );
}

export default App;
