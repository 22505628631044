export default function Inicio() {
  return (
    <div className="container marketing">
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">
            ¡Bienvenidos a Fundación Vence las Barreras!
          </h2>
          <p className="lead">
            En esta comunidad cristiana, trabajamos con pasión para derribar las
            barreras de la comunicación y facilitar la accesibilidad a la
            información integral para personas sordas. <br /> A través de la
            Lengua de Señas Chilena, capacitamos, apoyamos y acompañamos,
            creando un ambiente inclusivo y solidario. Únete a nuestra misión de
            amor y transformación. ¡Bienvenidos a una experiencia única en
            Cristo!
          </p>
        </div>
        <div className="col-md-5">
          <img
            src="../img/page/Imagen1.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row mb-5">
        <div className="col-md-12">
          <h3 className="featurette-heading-title fw-normal lh-1 text-center">
            FACILITANDO LA ACCESIBILIDAD A LA INFORMACIÓN INTEGRAL PARA PERSONAS
            SORDAS
          </h3>
        </div>
      </div>
      <br />
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">
            NUESTRO COMPROMISO
          </h2>
          <p className="lead">
            En Fundación Vence las Barreras, nuestro compromiso es asegurar que
            todas las personas sordas tengan acceso a información integral.{" "}
            <br /> Nos enfocamos en formar y capacitar en Lengua de Señas
            Chilena para lograr la inclusión de aquellos que padecen
            discapacidad auditiva, social, educacional y laboral.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen5.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">
            NUESTRA PREOCUPACIÓN
          </h2>
          <p className="lead">
            Además de la capacitación en Lengua de Señas Chilena, también nos
            preocupamos por atender las necesidades espirituales de las personas
            sordas. <br /> Facilitamos el acceso al conocimiento de la Palabra
            de Dios como guía y práctica de fe, con el apoyo de intérpretes en
            Lengua de Señas Chilena.
          </p>
        </div>
        <div className="col-md-5">
          <img
            src="../img/page/Imagen6.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">NUESTRO EQUIPO</h2>
          <p className="lead">
            Nuestro equipo de colaboradores trabaja arduamente para llevar a
            cabo proyectos de alcance nacional. <br /> Contamos con una red de
            voluntarios y colaboradores que nos permiten desarrollar tareas de
            apoyo y acompañamiento, enriqueciendo aún más nuestra labor en
            beneficio de la comunidad sorda.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen8.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">
            CREEMOS CON PASIÓN
          </h2>
          <p className="lead">
            En Fundación Vence las Barreras, creemos firmemente que la
            accesibilidad a la información es un derecho fundamental de todas
            las personas. <br /> Con pasión y dedicación, trabajamos cada día
            para derribar barreras y construir un futuro más inclusivo y
            solidario para todas las personas sordas. ¡Únete a nuestra causa y
            se parte del cambio positivo que generamos día a día!
          </p>
        </div>
        <div className="col-md-5">
          <img
            src="../img/page/Imagen9.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
    </div>
  );
}
