export default function QueHacemos() {
  return (
    <div className="container marketing">
      <div className="row mb-5">
        <div className="col-md-12">
          <h3 className="featurette-heading-title fw-normal lh-1 text-center">
            ¿QUE HACEMOS?
          </h3>
        </div>
      </div>
      <br />
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">
            NUESTRA MISIÓN DE AMOR Y SERVICIO CRISTIANO
          </h2>
          <p className="lead">
            En Fundación Vence las Barreras, nuestra labor está enraizada en el
            amor de Cristo, guiándonos en cada paso que damos para marcar una
            diferencia en la vida de las personas sordas y sus familias. Nuestra
            misión es clara y llena de propósito, y estamos comprometidos a
            seguir el ejemplo de nuestro Señor en todo lo que hacemos. <br />
            <br />
            <strong>
              1. Capacitaciones en la Lengua de Señas Chilena a sordos y
              oyentes:{" "}
            </strong>
            Como servidores de Cristo, entendemos que la comunicación es una
            poderosa herramienta para romper barreras y unir corazones. A través
            de nuestras capacitaciones en la Lengua de Señas Chilena, buscamos
            fortalecer los lazos entre personas sordas y oyentes, permitiendo
            que todos se comuniquen efectivamente y se comprendan mutuamente.{" "}
            <br />
            <strong>
              {" "}
              2. Apoyo y acompañamiento a las personas sordas y sus familias:{" "}
            </strong>
            Nuestra fe cristiana nos impulsa a ser un apoyo incondicional para
            aquellos que enfrentan desafíos debido a la discapacidad auditiva.
            Brindamos acompañamiento en cada paso del camino, extendiendo una
            mano amorosa y compasiva para aliviar cargas y compartir esperanza
            en tiempos difíciles. <br />
            <strong>
              {" "}
              3. Enseñanza Bíblica con interpretación en Lengua de Señas
              Chilena:{" "}
            </strong>
            Como fieles seguidores de Cristo, compartimos el mensaje de amor y
            esperanza contenido en la Palabra de Dios. A través de la enseñanza
            bíblica con interpretación en Lengua de Señas Chilena, aseguramos
            que todos puedan acceder y comprender el mensaje de Dios, sin
            importar sus capacidades auditivas. <br />
            <strong>
              4. Formación de misioneros cristianos como facilitadores de la
              comunicación del evangelio en Lengua de Señas Chilena:
            </strong>
            Creemos que el evangelio de Cristo debe ser llevado a todas las
            personas, sin excepción. Formamos misioneros cristianos que, con un
            corazón dispuesto y la habilidad de comunicarse en Lengua de Señas
            Chilena, puedan llevar el mensaje de salvación y esperanza a las
            personas sorda y sus familias. <br />
            <strong>
              5. Asesorías a Organizaciones sociales, religiosas y
              empresariales:{" "}
            </strong>
            Creemos que nuestra responsabilidad como cristianos va más allá de
            nuestra propia Fundación. Nos ponemos a disposición de otras
            organizaciones sociales, religiosas y empresariales, ofreciendo
            asesoría para crear un entorno más inclusivo y comprensivo para las
            personas sordas en todas las áreas de la sociedad.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen33.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">
            NUESTRAS ACCIONES
          </h2>
          <p className="lead">
            En Fundación Vence las Barreras, cada acción que tomamos está
            impregnada de nuestro enfoque cristiano. Buscamos ser siervos
            humildes de Cristo, sirviendo a los demás con amor y dedicación, y
            siendo un reflejo del amor incondicional de nuestro Salvador.
            <br /> Con el respaldo de nuestra fe y la guía del Señor, seguiremos
            trabajando incansablemente para vencer barreras y marcar una
            diferencia positiva en la vida de aquellos a quienes servimos.
            ¡Únete a nosotros en esta hermosa misión de amor y servicio
            cristiano!
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src="../img/page/Imagen34.webp"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          />
        </div>
      </div>
    </div>
  );
}
